export default function parseApiError(apiResult, intl) {
  let errorObject;
  let errorMessage;

  try {
    errorObject = JSON.parse(apiResult.error?.message)['data']['errors'];
  } catch (e) {
    console.error(e);
  }

  if (errorObject) {
    if (Object.keys(errorObject).length) {
      errorMessage = Object.keys(errorObject)
        .map(key => {
          return errorObject[key];
        })
        .join(', ');
    }
  } else {
    try {
      errorMessage = JSON.parse(apiResult.error?.message)['data'];
    } catch (e) {
      console.error(e);
    }

    errorObject = {};
  }

  if (errorMessage && errorMessage.length) {
    if (
      errorObject.unique_consumer_name_in_organization ||
      errorObject.unique_provider_name_in_organization ||
      errorObject.unique_unit_name_in_group ||
      errorObject.unique_group_name_in_domain
    ) {
      return intl.formatMessage({
        id: 'error_unique_name',
        defaultMessage:
          'The name used is already assigned. Please adjust the name.'
      });
    }

    if (errorObject.unique_yearly_holiday_name_in_organization) {
      return intl.formatMessage({
        id: 'error_unique_yearly_holiday_name_in_organization',
        defaultMessage:
          'There are already holidays for the selected year with the same name.'
      });
    }

    if (errorObject.unique_yearly_public_holiday_name_in_organization) {
      return intl.formatMessage({
        id: 'error_unique_yearly_public_holiday_name_in_organization',
        defaultMessage:
          'There is already a public holiday for the selected year with the same name.'
      });
    }

    if (errorObject.unique_yearly_season_name_in_organization) {
      return intl.formatMessage({
        id: 'error_unique_yearly_season_name_in_organization',
        defaultMessage:
          'There is already a season for the selected year with the same name.'
      });
    }

    if (errorObject.unique_yearly_season_name_in_provider) {
      return intl.formatMessage({
        id: 'error_unique_yearly_season_name_in_provider',
        defaultMessage:
          'There is already a season for the selected year with the same name.'
      });
    }

    if (errorObject.detail === 'Your account has already been confirmed') {
      return intl.formatMessage({
        id: 'error_user_already_confirmed',
        defaultMessage: 'The account has already been confirmed.'
      });
    }

    if (errorMessage === 'Invalid token or unconfirmed user.') {
      return intl.formatMessage({
        id: 'error_invalid_token_or_unconfirmed_user',
        defaultMessage: 'Invalid token or unconfirmed user.'
      });
    }

    if (
      errorMessage ===
      'Confirmation email resend failed, account does not exist or is already confirmed.'
    ) {
      return intl.formatMessage({
        id: 'error_user_confirm_resend',
        defaultMessage:
          'Confirmation email resend failed, account does not exist or is already confirmed.'
      });
    }

    if (errorMessage === "Password reset of blocked users isn't allowed.") {
      return intl.formatMessage({
        id: 'error_password_reset_of_blocked_user_not_allowed',
        defaultMessage: "Password reset of blocked users isn't allowed."
      });
    }

    if (
      errorMessage ===
      "Can't restore if there's already another booking for this booking_request."
    ) {
      return intl.formatMessage({
        id: 'error_create_booking_not_allowed_when_request_already_has_one',
        defaultMessage:
          "Can't restore if there's already another booking for this booking_request."
      });
    }

    if (
      errorMessage ===
      "Can't update a request which already has an associated booking."
    ) {
      return intl.formatMessage({
        id: 'error_request_cant_be_updated_when_booking_exists',
        defaultMessage:
          "Can't update a request which already has an associated booking."
      });
    }

    if (
      errorMessage ===
      "Can't archive a request which already has an associated booking."
    ) {
      return intl.formatMessage({
        id: 'error_request_cant_be_archived_when_booking_exists',
        defaultMessage:
          "Can't archive a request which already has an associated booking."
      });
    }

    if (errorMessage === "Can't archive a published booking.") {
      return intl.formatMessage({
        id: 'error_cant_archive_published_booking',
        defaultMessage: "Can't archive a published booking."
      });
    }

    if (
      errorMessage ===
      "Can't update start/end of a holiday which already has associated records."
    ) {
      return intl.formatMessage({
        id: 'error_cant_update_start_end_of_holiday_with_associated_records',
        defaultMessage:
          "Can't update start/end of a holiday which already has associated records."
      });
    }

    if (
      errorMessage ===
      "Can't archive a holiday which already has associated records."
    ) {
      return intl.formatMessage({
        id: 'error_cant_archive_holiday_with_associated_records',
        defaultMessage:
          "Can't archive a holiday which already has associated records."
      });
    }

    if (
      errorMessage ===
      "Can't update start/end of a season which already has associated records."
    ) {
      return intl.formatMessage({
        id: 'error_cant_update_start_end_of_season_with_associated_records',
        defaultMessage:
          "Can't update start/end of a season which already has associated records."
      });
    }

    if (
      errorMessage ===
      "Can't archive a season which already has associated records."
    ) {
      return intl.formatMessage({
        id: 'error_cant_archive_season_with_associated_records',
        defaultMessage:
          "Can't archive a season which already has associated records."
      });
    }

    if (
      errorMessage ===
      "Can't restore, the associated booking_request of this booking has been archived."
    ) {
      return intl.formatMessage({
        id: 'error_cant_restore_booking_with_archived_request',
        defaultMessage:
          "Booking can't be restored, as long as the associated request is archived."
      });
    }

    if (
      errorMessage ===
      "Can't restore if there's already another unit with the same name."
    ) {
      return intl.formatMessage({
        id: 'error_unique_name_unit_restore',
        defaultMessage: 'Please rename exisiting unit first.'
      });
    }

    if (
      errorMessage ===
      "Can't restore if there are already other holidays with the same name in the same year."
    ) {
      return intl.formatMessage({
        id: 'error_unique_name_holiday_restore',
        defaultMessage: 'Please rename exisiting holiday first.'
      });
    }

    if (
      errorMessage ===
      "Can't restore if there's already another public holiday with the same name in the same year."
    ) {
      return intl.formatMessage({
        id: 'error_unique_name_public_holiday_restore',
        defaultMessage: 'Please rename exisiting public holiday first.'
      });
    }

    if (
      errorMessage ===
      "Can't restore if there's already another season with the same name."
    ) {
      return intl.formatMessage({
        id: 'error_unique_name_season_restore',
        defaultMessage: 'Please rename exisiting season first.'
      });
    }

    if (errorMessage === 'units have to be directly connected') {
      return intl.formatMessage({
        id: 'error_units_not_conntected',
        defaultMessage: 'Units are not connected.'
      });
    }

    if (errorMessage === 'A required associated object is archived.') {
      return intl.formatMessage({
        id: 'error_cant_restore_while_required_object_is_archived',
        defaultMessage: 'A required associated object is archived.'
      });
    }

    if (
      errorMessage === 'outside of season boundaries' ||
      errorMessage === 'outside of holiday boundaries'
    ) {
      return 'Das Objekt kann nicht gespeichert werden, weil bei einer Terminserie entweder keine Termine (mehr) vorhanden sind oder weil ein generierter Termin außerhalb der definierten Zeiträume liegt.';
    }

    if (
      errorObject.detail &&
      errorMessage.startsWith(
        `${intl.formatMessage({
          id: 'api_error_auth',
          defaultMessage: 'You need to login to view this resource'
        })}`
      )
    ) {
      return intl.formatMessage({
        id: 'error_username_password',
        defaultMessage: 'Username or password is wrong'
      });
    }

    if (
      errorObject.email &&
      errorMessage.startsWith(
        `${intl.formatMessage({
          id: 'api_error_has_already_been_taken',
          defaultMessage: 'has already been taken'
        })}`
      )
    ) {
      return intl.formatMessage({
        id: 'error_email_has_already_been_taken',
        defaultMessage: 'This email address is already in use.'
      });
    }

    if (
      errorMessage.startsWith(
        `${intl.formatMessage({
          id: 'api_error_has_already_been_taken',
          defaultMessage: 'has already been taken'
        })}`
      )
    ) {
      return intl.formatMessage({
        id: 'error_unique_name',
        defaultMessage: 'The name is in usage'
      });
    }

    if (
      errorMessage.startsWith(
        `${intl.formatMessage({
          id: 'api_error_forbidden',
          defaultMessage: 'You are not authorized to view this resource'
        })}`
      )
    ) {
      return intl.formatMessage({
        id: 'error_forbidden',
        defaultMessage: 'Not allowed'
      });
    }

    if (errorMessage === "start and end can't be the same")
      return intl.formatMessage({
        id: 'error_start_end',
        defaultMessage: 'The beginning must be before the end.'
      });

    if (errorMessage.includes('end has to be greater than start'))
      return intl.formatMessage({
        id: 'error_start_end',
        defaultMessage: 'The beginning must be before the end.'
      });
  }

  return errorMessage
    ? errorMessage
    : intl.formatMessage({
        id: 'error_unexpected',
        defaultMessage: 'Unexpected error occured.'
      });
}
